import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AOS from "aos";
import "aos/dist/aos.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import "@/assets/nucleo/css/nucleo-icons.css";
import VuePromptpayQr from 'vue-promptpay-qr'
import MarqueeText from 'vue-marquee-text-component'
// import { HTTP } from "@/services/http-common";

Vue.component("font-awesome-icon");

Vue.config.productionTip = false;

Vue.use(Loading);
Vue.use(Toast);
Vue.use(VueMeta);
Vue.use(VueSweetalert2);
Vue.use(VuePromptpayQr);
Vue.component('marquee-text', MarqueeText)

// HTTP.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("lb_token");
//     if (token) {
//       config.headers.common["Authorization"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// HTTP.interceptors.response.use(
//   (response) => {
//     if (response.status === 200 || response.status === 201) {
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
//   (error) => {
//     if (error.response.status) {
//       switch (error.response.status) {
//         case 400:
//           //do something
//           break;

//         case 401:
//           router.replace({
//             path: "/login",
//           });
//           break;
//         case 403:
//           router.replace({
//             path: "/login",
//             query: { redirect: router.currentRoute.fullPath },
//           });
//           break;
//         case 404:
//           alert("page not exist");
//           break;
//         case 502:
//           setTimeout(() => {
//             router.replace({
//               path: "/login",
//               query: {
//                 redirect: router.currentRoute.fullPath,
//               },
//             });
//           }, 1000);
//       }
//       return Promise.reject(error.response);
//     }
//   }
// );
new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
