import Vue from "vue";
import VueRouter from "vue-router";
const MainPageLayout = () => import("@/views/Layout/MainPageLayout.vue");
const ZeanLayout = () => import("@/views/Layout/ZeanLayout.vue");
const RankLayout = () => import("@/views/Layout/RankLayout.vue");
const LoadingLayout = () => import("@/views/Layout/LoadingLayout.vue");
const Home = () => import("@/views/Home.vue");
const CasinoComponent = () => import("@/views/Casino.vue");
const SlotComponent = () => import("@/views/Slot.vue");
const FishingComponent = () => import("@/views/Fishing.vue");
const Promotion = () => import("@/views/Promotion.vue");
const SportComponent = () => import("@/views/Sport.vue");
const Zean = () => import("@/views/Zean.vue");
const Ranking = () => import("@/views/Ranking.vue");
const Playgame = () => import("@/views/PlayGame.vue");
const LoadingGame = () => import("@/views/Loading.vue");
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainPageLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      // {
      //   path: "/loading-game/:provider/:game",
      //   name: "loadinggame",
      //   component: LoadingGame,
      // },
      {
        path: "/casino",
        name: "casino",
        component: CasinoComponent,
      },
      {
        path: "/slot",
        name: "slot",
        component: SlotComponent,
      },
      {
        path: "/fishing",
        name: "fishing",
        component: FishingComponent,
      },
      {
        path: "/sport",
        name: "sport",
        component: SportComponent,
      },
      {
        path: "/promotion",
        name: "promotion",
        component: Promotion,
      },
      {
        path: "/playgame",
        name: "playgame",
        component: Playgame,
      },
    ],
  },
  {
    path: "/",
    component: ZeanLayout,
    redirect: "/zean",
    children: [
      {
        path: "/zean",
        name: "zean",
        component: Zean,
      },
    ],
  },
  {
    path: "/",
    component: RankLayout,
    redirect: "/ranking",
    children: [
      {
        path: "/ranking",
        name: "ranking",
        component: Ranking,
      },
    ],
  },
  {
    path: "/",
    component: LoadingLayout,
    redirect: "/loading-game/",
    children: [
      {
        path: "/loading-game/:provider/:game",
        name: "loadinggame",
        component: LoadingGame,
      },
    ],
  },
];

const router = new VueRouter({
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // },

  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
